<template>
  <Teleport v-if="isOpened" to="#modal-teleport">
    <Content v-bind="props" :visible="isOpened" @close="close()">
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #popupInfo>
        <slot name="popupInfo"></slot>
      </template>
      <template #customHeader>
        <slot name="customHeader"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
      <slot></slot>
    </Content>
  </Teleport>
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

import { watch } from 'vue'
import Content from './content.vue'

import { UI_MODAL_PROPS } from '~/constants/sharedProps/modal'

const props = defineProps(UI_MODAL_PROPS)

// TODO: look into how to attach modal to body without style issues, at the moment I use important in tailwind.config to override the styles. Look into it more maybe we can change style or make them important in all cases

const emit = defineEmits(['close', 'right-button'])

// Modal state management
const { closeModal, isModalOpen } = useModalState()

const isOpened = computed(() => {
  return isModalOpen(props.modalName)
})

const close = () => {
  closeModal(props.modalName)
  emit('close')
}

watch(
  () => props.visible,
  () => {
    if (props.visible) {
      openModal(props.modalName)
      return
    }

    closeModal(props.modalName)
  },
)
</script>
